*{
    font-family: "Montserrat";
    text-decoration: none;
  }

.bg-street-info {
    width: 100%;
    min-height: 100vh;
    text-align: center;
    background-color: #2F3236;
    box-sizing: border-box;
    line-height: 1.6;
}

.bg-street-info .header {
  padding-top: 70px;
  margin-bottom:80px;
}

.go-back {
  height: 15px;
}

.bg-street-info .header Button{
  color: #FFFFFF;
  border-radius: 25px;
  height: 100%;
  padding: 10px 32px;
  cursor: auto;
}

.camera-img {
  margin-right: 12px;
}



.street-text span {
  color: #F9F9F9;
  font-weight: 200;
  font-size: 20pt;
  margin-top: 150px;
  text-align: center;
}

.info-text span {
  color: #F9F9F9;
  font-weight: 200;
  font-size: 16pt;
  text-align: justify;
  text-align-last: center;
  display: block;
  margin-top: 48px;
  line-height: 1.8;
}

.header .text-button span {
  margin-left: 0;
}

.send:hover {
  -webkit-box-shadow: 0px 0px 22px 0px #175755;
  -moz-box-shadow: 0px 0px 22px 0px #175755;
  box-shadow: 0px 0px 22px 0px #175755;
}

.send {
    text-align: center;
    margin-bottom: 64px;
    margin-top: 64px;
    border-radius: 20px;
    font-family: 'Montserrat', sans-serif;
    text-transform: none;
    font-size: 16px;
    background-image: linear-gradient(to right, #175755, #45b6a7);
    color: #F9F9F9;
    height: 100%;
    text-align: center;
    padding: 8px 32px;
    cursor: pointer;
    padding: 10px;
}

.vs-logo {
  height: 50px;
  margin-bottom: 32px;
}

@media (max-width: 600px) {

  .bg-street-thanks .header Button{
    color: #FFFFFF;
    border-radius: 25px;
    font-size: 8pt;
    height: 100%;
    padding: 8px 16px;
  }

  .bg-street-thanks .header Button b {
    font-size: 12pt;
  }

}