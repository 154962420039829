*{
    font-family: "Montserrat";
    text-decoration: none;
  }

.bg-alarm-thanks {
    width: 100%;
    min-height: 100vh;
    text-align: center;
    background-color: #ffffff;
    box-sizing: border-box;
    background: url(./images/alarm-bg.png) #2F3236 no-repeat 500px;
    line-height: 1.6;
}

.bg-alarm-thanks .header {
  margin-bottom:80px;
}

.go-back {
  height: 15px;
}

.bg-alarm-thanks .header Button{
  color: #FFFFFF;
  background-color: #ffffff;
  border-radius: 25px;
  height: 100%;
  padding: 10px 32px;
  cursor: auto;
}

.camera-img {
  margin-right: 12px;
}




.alarm-text span {
  color: #F9F9F9;
  font-weight: 200;
  font-size: 20pt;
  margin-top: 150px;
  text-align: center;
}

@media (max-width: 600px) {

  .bg-alarm-thanks .header Button{
    color: #FFFFFF;
    background-color: #ffffff;
    border-radius: 25px;
    height: 100%;
    padding: 8px 25px;
  }
  .bg-alarm-thanks .header Button b {
    font-size: 12pt;
  }

}