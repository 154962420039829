*{
    font-family: "Montserrat";
  }

.bg-alarm-options {
    width: 100vw;
    min-height: 100%;
    text-align: center;
    background: url(./images/alarm-bg.png) #2F3236 no-repeat 500px;
    line-height: 1.6;
}

.bg-alarm-options .header {
  padding-top: 70px;
}

.bg-alarm-options .header Button{
  color: #FFFFFF;
  border-radius: 25px;
  font-size: 14pt;
  height: 100%;
  padding: 10px 60px;
  cursor: auto;
}

.alarm-img {
  margin-right: 12px;
}

.header span {
  color: #F9F9F9;
  font-weight: 200;
}

.option-text p {
  color: #F9F9F9;
  font-weight: 200;
  font-size: 18pt;
}

.bg-alarm-options .buttons Button {
    border-radius: 20px;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    border: 1px solid #2f3236;
    color: #FFFFFF;
    height: 100%;
    
  }

.bg-alarm-options .buttons Button:hover {
    background-image: linear-gradient(to right, #175755, #45b6a7);
    color: #FFFFFF;
    border: 1px solid #2F3236;
  }

  @media (max-width: 600px) {

    .bg-alarm-options .header Button{
      color: #FFFFFF;
      border-radius: 25px;
      font-size: 10px;
      height: 100%;
      padding: 8px 25px;
    }

    .bg-alarm-options .header Button b {
      font-size: 12pt;
    }
  
  }