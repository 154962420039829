*{
  font-family: "Montserrat";
}

.bg {
  background: url(./images/gray-bg.png) no-repeat right 250px;
  width: 100%;
  min-height: 100vh;
}

.buttons Button {
  border-radius: 20px;
  font-family: 'Montserrat', sans-serif;
  text-transform: none;
  font-size: 18px;
  border: 1px solid #45b6a7;
  color: #303236;
  height: 100%;
}

.buttons a {
  text-decoration: none;
  color: #303236;
}

.buttons Button:hover {
  background-image: linear-gradient(to right, #175755, #45b6a7);
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
}

.button {
  border-radius: 20px;
  font-family: 'Montserrat', sans-serif;
  text-transform: none;
  font-size: 18px;
  border: 1px solid #45b6a7;
  color: #303236;
  height: 100%;
}

.buttons {
  margin-top: 28px;
}
.bg-image {
  text-align: right;
}

.title {
  font-weight: 200;
  font-size: 40pt;
  margin-bottom: 0;
  color: #2F3236;
}

.title b {
  color: #45b6a7;
}

.subtitle {
color: #2F3236;
font-weight: 200;
font-size: 20pt;
margin-top: 16px;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (max-width: 600px) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }

  .bg {
    background: url(./images/gray-bg.png) no-repeat right 250px;
    width: 100vw;
    height: 100vh;
  }

  .bg .vs-logo {
    height: 30px;
    justify-content: flex-start;
  }

  .title {
    font-weight: 200;
    font-size: 26px;
    margin-top: 32px;
    color: #2F3236;
  }

  .subtitle {
    color: #2F3236;
    font-weight: 200;
    font-size: 24px;
    margin-top: 16px;
    }

  .buttons Button {
    font-size: 20px;
  }


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
}

