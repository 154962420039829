*{
    font-family: "Montserrat";
  }

.bg-alarm {
    width: 100vw;
    height: 100%;
    text-align: center;
  }

  .alarm {
    font-weight: 200;
    color: #303236;
    font-size: 22pt;
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .bg-alarm Button.more-info {
    margin-top: 0px;
    background-image: linear-gradient(to right, #175755, #45b6a7);
  }
  
  .bg-alarm Button.more-info:hover {
    -webkit-box-shadow: 0px 0px 22px 0px #175755;
    -moz-box-shadow: 0px 0px 22px 0px #175755;
    box-shadow: 0px 0px 22px 0px #175755;
    cursor: pointer;
  }

.bg-alarm Button {
    color: #FFFFFF;
    background-color: #2F3236;
    border-radius: 25px;
    font-size: 14pt;
    height: 100%;
    margin-top: 128px;
    padding: 10px 60px;
    cursor: auto;
}

.bg-alarm Button:hover {
    background-color: #2F3236;
}

.bg-alarm img.alarm-img {
    margin-right: 12px;
}

.alarm-arrow:hover {
    cursor: pointer;
    margin-top: 5px;
    transition: .2s;
}

@media (max-width: 600px) {

  .alarm {
    font-size: 18pt;
  }

  .bg-alarm Button {
    margin-top: 16px;
  }

  .bg-alarm Button.more-info {
    font-size: 12pt;
  }

}

